import React from "react";
import { Link } from "~components";
import cn from "classnames";
import { capitalizeString } from "~utils/helpers";
import * as styles from "./styles.module.scss";

/** ============================================================================
 * @component
 * Core Button component.
 * @param  {string}    buttonType   Usually one of "button" or "submit"
 * @param  {node}      children     Button text, icons if required
 * @param  {string}    className    For additional styling config
 * @param  {function}  onClick      Click listener function
 * @param  {boolean}   transparent  Fill state of the button
 * @param  {boolean}   disabled     Disable button
 * @return {node}                   Compiled Button JSX
 */
const Button = ({
  innerRef,
  to,
  title,
  children,
  className,
  color = `black`,
  onClick,
  transparent = false,
  disabled = false,
  buttonType = `button`
}) => {
  const innerJSX = (
    <div className={styles.button__innerContent}>{children}</div>
  );

  if (to) {
    return (
      <Link
        innerRef={innerRef}
        tabIndex={disabled ? -1 : 0}
        className={cn(
          className,
          styles.button,
          styles[`button${capitalizeString(color.toLowerCase())}`],
          `button-text`,
          {
            [styles.transparent]: transparent
          }
        )}
        to={to}
        title={title || ``}
      >
        {innerJSX}
      </Link>
    );
  }

  return (
    <button
      ref={innerRef}
      tabIndex={disabled ? -1 : 0}
      type={buttonType === `button` ? `button` : `submit`}
      className={cn(
        className,
        styles.button,
        styles[`button${capitalizeString(color.toLowerCase())}`],
        `button-text`,
        {
          [styles.transparent]: transparent
        }
      )}
      disabled={disabled}
      onClick={onClick}
    >
      {innerJSX}
    </button>
  );
};

export default Button;
