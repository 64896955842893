// extracted by mini-css-extract-plugin
export var appear = "styles-module--appear--fCBjc";
export var appearDown = "styles-module--appear-down--4vLMG";
export var appearLeft = "styles-module--appear-left--Ni1LJ";
export var appearRight = "styles-module--appear-right--b7YFY";
export var appearUp = "styles-module--appear-up--QjWRx";
export var desktop = "1340px";
export var desktopContainer = "styles-module--desktopContainer--U8qJh";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var logoImage = "styles-module--logoImage--nzM-r";
export var logoLink = "styles-module--logoLink--ixAsr";
export var mobile = "400px";
export var navItems = "styles-module--navItems---7DOm";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";