import React, { useEffect, useRef, useState } from "react";
import { ChatWidget, Grid, TextFlip3d } from "~components";
import * as styles from "./styles.module.scss";

const AtfDesktop = ({ activeSlideIndex, slides, className }) => {
  const [contentOffsetLeft, setContentOffsetLeft] = useState(null);

  const contentRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (!contentRef?.current) return;
      setContentOffsetLeft(contentRef?.current?.offsetLeft);
    };
    window.addEventListener(`resize`, handleResize);

    handleResize();

    return () => window.removeEventListener(`resize`, handleResize);
  }, []);

  return (
    <div
      className={[className, styles.container].join(` `)}
      style={{ background: slides[activeSlideIndex].color }}
    >
      <Grid className={styles.grid}>
        <div className={styles.body} ref={contentRef}>
          {slides.map((slide, slideIndex) => {
            const isVisible = activeSlideIndex === slideIndex;
            return (
              <figure
                key={`lgf-hero-slide-image-${slide.subheading}`}
                className={`${styles.imageWrapper} `}
              >
                <img
                  className={`${styles.image} ${!isVisible && styles.hidden}`}
                  src={slide.img}
                  alt={slide.subheading}
                />
              </figure>
            );
          })}

          <div className={styles.content}>
            <Grid className={styles.contentGrid}>
              <div
                className={styles.text}
                style={{ color: slides[activeSlideIndex].color }}
              >
                <h1
                  className={`d1 ${styles.heading}`}
                  style={{
                    top: 0,
                    left: `-${contentOffsetLeft}px`
                  }}
                >
                  Face
                </h1>

                <TextFlip3d
                  className={`d1 ${styles.subheading}`}
                  text={slides[activeSlideIndex].subheading}
                />
                <p className={`b1 ${styles.caption}`}>
                  {slides[activeSlideIndex].caption}
                </p>
              </div>

              <div
                key={`lgf-hero-slide-chat-widget-${slides[activeSlideIndex].subheading}`}
                className={styles.widgetWrapper}
              >
                <ChatWidget
                  type={slides[activeSlideIndex].widgetType}
                  sender={slides[activeSlideIndex].widgetSender}
                  content={slides[activeSlideIndex].widgetContent}
                />
              </div>
            </Grid>
          </div>
        </div>
      </Grid>
    </div>
  );
};

export default AtfDesktop;
