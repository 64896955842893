// extracted by mini-css-extract-plugin
export var appear = "styles-module--appear--R6qZi";
export var appearDown = "styles-module--appear-down--NJIlM";
export var appearLeft = "styles-module--appear-left--v6I9q";
export var appearRight = "styles-module--appear-right--aZwrV";
export var appearUp = "styles-module--appear-up--UGVUz";
export var arrowLeft = "styles-module--arrowLeft--1NEQG";
export var arrowRight = "styles-module--arrowRight--zj7oB";
export var container = "styles-module--container--SrEZO";
export var navButton = "styles-module--navButton--UFBq2";
export var navButtonAlone = "styles-module--navButtonAlone--iAu37";
export var navButtonInner = "styles-module--navButtonInner--wr0Hj";