import React from "react";
import { Colors, Fonts } from "~components";

const Theme = () => (
  <>
    <Colors />
    <Fonts />
  </>
);

export default Theme;
