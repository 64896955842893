import { useSlices } from "@loveandmoney/lam-dbp-slices";

//
// get a list of all available fragments for copying into page queries

export const useDBPFragments = () => {
  const { sliceIds } = useSlices();

  const fragments = sliceIds.map((sliceId) => `${sliceId}Fragment`);

  return {
    fragments
  };
};

export default useDBPFragments;
