// extracted by mini-css-extract-plugin
export var appear = "styles-module--appear--3pN2l";
export var appearDown = "styles-module--appear-down--8rAjJ";
export var appearLeft = "styles-module--appear-left--j2tiD";
export var appearRight = "styles-module--appear-right--++6MS";
export var appearUp = "styles-module--appear-up--lIpTN";
export var desktop = "1340px";
export var giant = "2200px";
export var hamburger = "styles-module--hamburger--qgOT9";
export var header = "styles-module--header--wsCdM";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var line = "styles-module--line--A8RhE";
export var mobile = "400px";
export var nav = "styles-module--nav--HhBXB";
export var navItems = "styles-module--navItems--pgjzt";
export var open = "styles-module--open--XlAZW";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var xButton = "styles-module--xButton---rbJN";
export var xIcon = "styles-module--xIcon--E+pk0";