import { useState, useEffect } from "react";
import * as bp from "~styles/breakpoints.module.scss";

const useBreakpoints = () => {
  const [breakpoints, setBreakpoints] = useState({
    ...bp
  });

  const bpArray = Object.keys(bp);

  // ---------------------------------------------------------------------------
  // methods

  const parseBpStringToInt = (bpStringWithPx) =>
    parseInt(bpStringWithPx.replace(`px`, ``));

  const mixin = (key, bound = `min`) => {
    if (!key || !bp?.[key]) {
      return null;
    }

    return `@media only screen and (${bound}-width: ${bp[key]})`;
  };

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    const handleResize = () => {
      const updatedBreakpoints = {};

      for (let i = 0; i < bpArray.length; i += 1) {
        updatedBreakpoints[bpArray[i]] =
          window.innerWidth >= parseBpStringToInt(bp[bpArray[i]]);
      }

      setBreakpoints(updatedBreakpoints);
    };

    window.addEventListener(`resize`, handleResize);

    handleResize();

    return () => window.removeEventListener(`resize`, handleResize);
  }, []);

  // ---------------------------------------------------------------------------
  // return

  return {
    breakpoints,
    mixin
  };
};

export default useBreakpoints;
