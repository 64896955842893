// extracted by mini-css-extract-plugin
export var body = "styles-module--body--CFKEO";
export var caption = "styles-module--caption--uzr8R";
export var container = "styles-module--container--2MLTe";
export var content = "styles-module--content--Ozl-Z";
export var contentGrid = "styles-module--contentGrid--mN4-O";
export var desktop = "1340px";
export var giant = "2200px";
export var grid = "styles-module--grid--EtlSt";
export var heading = "styles-module--heading--YSYa5";
export var hidden = "styles-module--hidden--K2pCL";
export var image = "styles-module--image--3ev+8";
export var imageWrapper = "styles-module--imageWrapper--CND54";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var messageGrow = "styles-module--messageGrow--U8b0T";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var subheading = "styles-module--subheading--EFU8a";
export var tablet = "769px";
export var text = "styles-module--text--Z+OcE";
export var widgetWrapper = "styles-module--widgetWrapper--vlwZ1";