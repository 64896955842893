import React from "react";
import { SVG } from "~components";
import * as styles from "./styles.module.scss";

const Weather = () => (
  <div className={styles.container}>
    <div className={styles.column}>
      <div className={styles.temperature}>25°</div>
      <div className="caption">Melbourne, AUS</div>
    </div>
    <div className={styles.column}>
      <div>
        <SVG className={styles.icon} svg="SunFilled" />
      </div>
      <div className="caption">H: 25° L: 16° </div>
    </div>
  </div>
);

export default Weather;
