import React from "react";
import { useAppContext } from "~hooks";
import { SVG } from "~components";
import * as styles from "./styles.module.scss";

// Todo connect button to controls for current ATF slide

const NextSlideButton = ({ onClick }) => {
  const { primaryColor } = useAppContext();
  return (
    <button
      type="button"
      aria-label="Next carousel slide"
      style={{ background: primaryColor }}
      className={styles.button}
      onClick={onClick}
    >
      <SVG svg="X" className={styles.mobileIcon} />
      <SVG svg="ArrowRight" className={styles.desktopIcon} />
    </button>
  );
};

export default NextSlideButton;
