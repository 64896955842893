import React from "react";
import {
  Weather,
  MessageContainer,
  TypingDots,
  Retrograde,
  MeetingAlert,
  NextSlideButton
} from "~components";

const ChatWidget = ({ type, sender, content, onClick }) => (
  /** ============================================================================
   * @component
   * Various widgets used for the text chat animation
   *
   * @typedef {('message' | 'typing' | 'weather' | 'meeting' | 'retrograde' | 'nextButton')} Type
   * @typedef {('incoming' | 'outgoing')} Sender
   *
   * @param 	{string}  		content			 	 Content of `message` type
   * @param 	{Type}   	    type			 	   Chat widget variant
   * @param 	{Sender}		  buttonType		 If the message is being sent or received - adds bg-color and tail
   * @param 	{function}		onClick		 		 Function for button when clicked
   * @return	{node}
   */

  <>
    {type === `message` && (
      <MessageContainer sender={sender}>
        <span className="b1">{content}</span>
      </MessageContainer>
    )}

    {type === `typing` && (
      <MessageContainer sender="incoming">
        <TypingDots />
      </MessageContainer>
    )}

    {type === `meeting` && (
      <MessageContainer fluid>
        <MeetingAlert />
      </MessageContainer>
    )}

    {type === `weather` && (
      <MessageContainer fluid>
        <Weather />
      </MessageContainer>
    )}

    {type === `retrograde` && (
      <MessageContainer>
        <Retrograde />
      </MessageContainer>
    )}

    {type === `nextButton` && (
      <MessageContainer nextButton>
        <NextSlideButton onClick={onClick} />
      </MessageContainer>
    )}
  </>
);

export default ChatWidget;
