import { useEffect, useState } from "react";
import {
  useSanityPages,
  useSanityCustomPages,
  useSanitySettings
} from "~hooks";
import sidebarLinks from "~constants/sidebar";

export const useSidebarLinks = () => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const { pages, getEmptySlugs } = useSanityPages();
  const { customPages, getEmptyCustomSlugs } = useSanityCustomPages();
  const { settings } = useSanitySettings();

  const [links, setLinks] = useState(sidebarLinks);
  const [groups, setGroups] = useState(null);

  // ----------------------------------------------------------------------
  // methods

  const getPrevValidLink = (currentIndex, comparatorArray) =>
    links
      .slice()
      .reverse()
      .find(({ url }, linkIndex) => {
        const inverseIndex = links.length - linkIndex - 1;

        return (
          inverseIndex < currentIndex &&
          !comparatorArray?.includes(url.replace(`/`, ``))
        );
      });

  const getPrevLink = (searchSlug) => {
    const currentLinkIndex = links.findIndex(
      ({ url }) =>
        url === searchSlug ||
        `/${url}` === searchSlug ||
        `${url}/` === searchSlug ||
        `/${url}/` === searchSlug
    );

    const prevLink = getPrevValidLink(currentLinkIndex, [
      ...getEmptySlugs(),
      ...getEmptyCustomSlugs()
    ]);

    return prevLink;
  };

  const getNextValidLink = (currentIndex, comparatorArray) =>
    links.find(
      ({ url }, linkIndex) =>
        linkIndex > currentIndex &&
        !comparatorArray?.includes(url.replace(`/`, ``))
    );

  const getNextLink = (searchSlug) => {
    const currentLinkIndex = links.findIndex(
      ({ url }) =>
        url === searchSlug ||
        `/${url}` === searchSlug ||
        `${url}/` === searchSlug ||
        `/${url}/` === searchSlug
    );

    const nextLink = getNextValidLink(currentLinkIndex, [
      ...getEmptySlugs(),
      ...getEmptyCustomSlugs()
    ]);

    return nextLink;
  };

  // ----------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (
      !customPages?.[0] ||
      links?.length === pages.length + customPages.length
    ) {
      return;
    }

    const sortedPages = customPages.map(({ category, slug, title }) => ({
      category,
      label: title,
      url: `/${slug.current}`
    }));

    const categoryOrder = settings?.sidebar?.customCategoryOrder;

    if (categoryOrder) {
      sortedPages.sort(
        (a, b) =>
          (categoryOrder.indexOf(a?.category) > -1
            ? categoryOrder.indexOf(a?.category)
            : Infinity) -
          (categoryOrder.indexOf(b?.category) > -1
            ? categoryOrder.indexOf(b?.category)
            : Infinity)
      );
    }

    setLinks([...sidebarLinks, ...sortedPages]);
  }, [customPages, pages, settings]);

  useEffect(() => {
    if (!links?.[0]) {
      return;
    }

    const linkGroups = {};

    links.forEach((link) => {
      if (!linkGroups?.[link.category]) {
        linkGroups[link.category] = [];
      }

      linkGroups[link.category].push(link);
    });

    setGroups(linkGroups);
  }, [links, settings]);

  // ---------------------------------------------------------------------------

  return {
    groups,
    links,
    getPrevLink,
    getNextLink
  };
};

export default useSidebarLinks;
