// extracted by mini-css-extract-plugin
export var animateIn = "styles-module--animateIn--D-JSx";
export var container = "styles-module--container--XFsM5";
export var cube = "styles-module--cube--xBlMt";
export var cubeFace = "styles-module--cubeFace--y-zzf";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";