import React from "react";
import cn from "classnames";
import * as styles from "./styles.module.scss";

const MeetingAlert = () => (
  <div className={styles.container}>
    <div className={cn(`b1`, styles.h1)}>Meeting alert</div>
    <div className={styles.h2}>Mid-year review with head office</div>
    <div className="button-text">
      Starts at
      <span className={styles.meetingTime}>09:00AM</span>
    </div>
  </div>
);

export default MeetingAlert;
