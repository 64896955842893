import React from "react";
import { Link } from "~components";
import {
  useApp,
  useSanityPages,
  useSanityCustomPages,
  useSanitySettings
} from "~hooks";
import cn from "classnames";
import * as styles from "./styles.module.scss";

const SidebarGroup = ({ data: { name, links } }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const { pathname } = useApp();
  const { getPageBySlug } = useSanityPages();
  const { getCustomPageBySlug } = useSanityCustomPages();
  const { settings } = useSanitySettings();

  const activeHex = settings?.sidebar?.activeColour?.hex || `#919191`;
  const hoverHex = settings?.sidebar?.hoverColour?.hex || `#ffffff`;

  // ---------------------------------------------------------------------------
  // render

  return (
    <div className={styles.container}>
      <h4 className={cn(styles.heading, `b1`)}>{name || `Group`}</h4>

      <ul className={styles.list}>
        {links.map(({ label, url }) => {
          const active = url === pathname || `${url}/` === pathname;

          if (!url?.startsWith(`/`)) {
            url = `/${url}`;
          }

          let pageData = getPageBySlug(url);

          if (!pageData) {
            pageData = getCustomPageBySlug(url);
          }

          const disabled = !pageData?.pagebuilder?.slices?.[0];

          return (
            <li
              key={`sidebar-group-item-${label}`}
              className={cn(styles.listItem, {
                [styles.listItemActive]: active,
                [styles.listItemDisabled]: disabled
              })}
              style={{
                "--activecolor": activeHex,
                "--hovercolor": hoverHex
              }}
            >
              {(url && (
                <Link to={url} className="button-text">
                  {label}
                </Link>
              )) || (
                <p className={cn(`button-text`, styles.buttonText)}>{label}</p>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SidebarGroup;
