import React from "react";
import cn from "classnames";
import * as styles from "./styles.module.scss";

const Retrograde = ({
  headline = `Mecury is now in retrograde. Here’s what to do next.`
}) => (
  <>
    <div className={cn(`caption`, styles.header)}>Today&apos;s news</div>
    <div className={styles.headline}>{headline}</div>
  </>
);

export default Retrograde;
