import React from "react";
import { ChatWidget, TextFlip3d } from "~components";
import cn from "classnames";
import * as styles from "./styles.module.scss";

const AtfDesktop = ({ activeSlideIndex, slides }) => (
  <div
    className={styles.container}
    style={{ backgroundColor: slides[activeSlideIndex].color }}
  >
    <div className={styles.content}>
      <TextFlip3d
        className={`d1 ${styles.heading}`}
        text={`Face ${slides[activeSlideIndex].subheading}`}
      />
      <p className={cn(`b1`, styles.caption)}>
        {slides[activeSlideIndex].caption}
      </p>
      <div className={styles.imageContainer}>
        {slides.map((slide, slideIndex) => {
          const isVisible = activeSlideIndex === slideIndex;
          return (
            <img
              className={cn(styles.image, { [styles.hidden]: !isVisible })}
              src={slide.img}
              alt={slide.subheading}
            />
          );
        })}
        <div className={styles.chatContainer} key={activeSlideIndex}>
          <ChatWidget
            type={slides[activeSlideIndex].widgetType}
            sender={slides[activeSlideIndex].widgetSender}
            content={slides[activeSlideIndex].widgetContent}
          />
        </div>
      </div>
    </div>
  </div>
);

export default AtfDesktop;
