import React from "react";
import { Grid, Link } from "~components";
import { useSanityCustomPages, useSanityPages, useSidebarLinks } from "~hooks";
import * as styles from "./styles.module.scss";

const LgfMenulist = ({
  id,
  className,
  data: { backgroundColor, hoverColor, fontColor, listAlignment, textStyle }
}) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const { links } = useSidebarLinks();
  const { getPageBySlug } = useSanityPages();
  const { getCustomPageBySlug } = useSanityCustomPages();

  // ---------------------------------------------------------------------------
  // variables

  const alignmentClass = `container${listAlignment}`;

  // ---------------------------------------------------------------------------
  // render

  return (
    <section
      sliceid={id}
      className={[className, styles.container, styles[alignmentClass]].join(
        ` `
      )}
      style={{
        background: backgroundColor?.hex || `white`,
        color: fontColor?.hex || `black`
      }}
    >
      <Grid className={styles.grid}>
        {links !== null && (
          <ul className={styles.list}>
            {links.map(({ label, url }) => {
              let pageData = getPageBySlug(url);

              if (!pageData) {
                pageData = getCustomPageBySlug(url);
              }

              const disabled = !pageData?.pagebuilder?.slices?.[0];

              if (disabled) return null;

              return (
                <li
                  key={`sidebar-group-item-${label}`}
                  className={styles.listItem}
                >
                  {url && (
                    <Link to={url} className={`${textStyle?.style || `h1`}`}>
                      <span
                        className={styles.link}
                        style={{
                          "--hovercolor": hoverColor?.hex || `#E2FF2E`
                        }}
                      >
                        {label}
                      </span>
                    </Link>
                  )}
                </li>
              );
            })}
          </ul>
        )}
      </Grid>
    </section>
  );
};

export default LgfMenulist;
