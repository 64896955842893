import React, { useRef, useState, useEffect } from "react";
import cn from "classnames";
import { useWindowSize } from "~hooks";
import { Helmet } from "react-helmet";
import * as styles from "./styles.module.scss";

const TextFlip3d = ({ className, text }) => {
  const textRef = useRef();
  const [cubeHeight, setCubeHeight] = useState();
  const windowSize = useWindowSize();

  const cubeHeightCss = `:root{--cube-height: ${cubeHeight}px;}`;

  useEffect(() => {
    setCubeHeight(textRef?.current?.offsetHeight);
  }, [windowSize.width, text]);

  return (
    <>
      <Helmet>
        <style>{cubeHeightCss}</style>
      </Helmet>
      {/* <div className={styles.container}> */}
      <div className={`${className && className} ${styles.container}`}>
        <div key={text} className={styles.cube}>
          <h1 ref={textRef} className={cn(`d1`, styles.cubeFace)}>
            {text}
          </h1>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default TextFlip3d;
