// extracted by mini-css-extract-plugin
export var caption = "styles-module--caption--baST5";
export var chatContainer = "styles-module--chatContainer--DB90T";
export var container = "styles-module--container--1-WDg";
export var content = "styles-module--content--sdkWw";
export var desktop = "1340px";
export var giant = "2200px";
export var heading = "styles-module--heading--jPkvT";
export var hidden = "styles-module--hidden--lRobj";
export var image = "styles-module--image--gTawy";
export var imageContainer = "styles-module--imageContainer--KgJ3L";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var messageGrow = "styles-module--messageGrow--7dV3a";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";