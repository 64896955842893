// extracted by mini-css-extract-plugin
export var addColor = "styles-module--addColor--v4D8W";
export var addColorDescription = "styles-module--addColorDescription--gxbtQ";
export var addColorFormLabel = "styles-module--addColorFormLabel--Fmr+l";
export var addColorFormSubmit = "styles-module--addColorFormSubmit--p17Xh";
export var addColorHeading = "styles-module--addColorHeading--2chk2";
export var color = "styles-module--color--IlD7E";
export var colorEditor = "styles-module--colorEditor--nr+49";
export var colorEditorInput = "styles-module--colorEditorInput--621jv";
export var colorEditorRow = "styles-module--colorEditorRow--8zR6Y";
export var colorEditorRowLabel = "styles-module--colorEditorRowLabel--f98Tq";
export var colorIcon = "styles-module--colorIcon--ojn5C";
export var colorIconHex = "styles-module--colorIconHex--1wf6a";
export var colorInfo = "styles-module--colorInfo--4fjdN";
export var colorsCustom = "styles-module--colorsCustom--vH9dy";
export var colorsHeading = "styles-module--colorsHeading--MK++d";
export var colorsReadOnly = "styles-module--colorsReadOnly--eteAQ";
export var tableRow = "styles-module--tableRow--0VWfi";