import React from "react";
import { SVG } from "~components";
import cn from "classnames";
import * as styles from "./styles.module.scss";

const MessageContainer = ({ children, sender, nextButton, fluid }) => {
  /** ============================================================================
   * @component
   * Wrapper for the chat widgets
   *
   * @typedef {('incoming' | 'outgoing')} Sender
   *
   * @param 	{node}  			children			 Widget content
   * @param 	{Type}   	    Sender			 	 Adds background color and tails as required
   * @param 	{boolean}		  nextButton		 Removes wrapper styling for next button widget
   * @param 	{boolean}		  fluid		 			 Message takes full width of chat container
   * @return	{node}
   */

  const backgroundColor =
    sender === `outgoing` ? `var(--color-sick-lime)` : `var(--color-black-10)`;

  return (
    <div>
      <div
        className={cn(styles.container, {
          [styles.nextButton]: nextButton,
          [styles.fluid]: fluid
        })}
        style={{ backgroundColor: nextButton ? null : backgroundColor }}
      >
        {sender === `incoming` && (
          <SVG
            className={cn(styles.tail, styles.tailLeft)}
            style={{ color: backgroundColor }}
            svg="MessageTailLeft"
          />
        )}
        {sender === `outgoing` && (
          <SVG
            className={cn(styles.tail, styles.tailRight)}
            style={{ color: backgroundColor }}
            svg="MessageTailRight"
          />
        )}
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};

export default MessageContainer;
