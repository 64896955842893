const links = [
  // {
  //   category: `Elements`,
  //   label: `What are we working with?`,
  //   url: `/what-are-we-working-with`
  // },
  // {
  //   category: `Environment`,
  //   label: `The Water We Swim In`,
  //   url: `/the-water-we-swim-in`
  // },
  {
    category: `Essence`,
    label: `Our Purpose`,
    url: `/our-purpose`
  },
  {
    category: `Essence`,
    label: `Design Principles`,
    url: `/design-principles`
  },
  {
    category: `Explanation`,
    label: `Logo`,
    url: `/logo`
  },
  {
    category: `Explanation`,
    label: `Colors`,
    url: `/colors`
  },
  {
    category: `Explanation`,
    label: `Typography`,
    url: `/typography`
  },
  {
    category: `Explanation`,
    label: `Tone of Voice`,
    url: `/tone-of-voice`
  },
  {
    category: `Explanation`,
    label: `Graphic Language`,
    url: `/graphic-language`
  },
  {
    category: `Explanation`,
    label: `Art Direction`,
    url: `/art-direction`
  },
  {
    category: `Explanation`,
    label: `Motion Principles`,
    url: `/motion-principles`
  },
  {
    category: `Explanation`,
    label: `CSS Themes`,
    url: `/css-themes`
  },
  {
    category: `Explanation`,
    label: `Web Components`,
    url: `/web-components`
  },
  {
    category: `Explanation`,
    label: `Page Builder Slices`,
    url: `/page-builder-slices`
  },
  {
    category: `Expression`,
    label: `Brand Exploration`,
    url: `/brand-exploration`
  },
  {
    category: `Expression`,
    label: `Links + Resources`,
    url: `/links-and-resources`
  }
];

export default links;
