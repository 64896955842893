import React, { useState } from "react";
import { Button } from "~components";
import cn from "classnames";
import * as styles from "./styles.module.scss";

/** ============================================================================
 * @page
 * Static page routes @ /*.
 */
const PasswordOverlay = () => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const [active, setActive] = useState(true);
  const [formStatus, setFormStatus] = useState(``);
  const [password, setPassword] = useState(``);

  // ---------------------------------------------------------------------------
  // variables

  const correctPassword = `InterruptThePattern2023`;

  // ---------------------------------------------------------------------------
  // methods

  const validate = (e) => {
    e.preventDefault();

    if (correctPassword === password) {
      setActive(false);
    } else {
      setFormStatus(`Incorrect password.`);
    }

    return false;
  };

  // ---------------------------------------------------------------------------
  // render

  if (!active) {
    return null;
  }

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={validate}>
        <input
          className={cn(styles.formInput, `h3`)}
          onChange={(e) => setPassword(e.currentTarget.value)}
          placeholder="Enter password"
          type="password"
        />

        <Button
          className={styles.button}
          buttonType="submit"
          onClick={() => {}}
        >
          <span className="button-text">Enter</span>
        </Button>

        <p className={cn(styles.formStatus, `caption`)}>{formStatus}</p>
      </form>
    </div>
  );
};

export default PasswordOverlay;
