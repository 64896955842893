import React from "react";
import * as styles from "./styles.module.scss";

const TypingDots = () => (
  // The spans ensure that the height will match a single line message,
  // and also space out the dots
  <div className={styles.container}>
    <div className={styles.dot} />
    <span className="b1">.</span>
    <div className={styles.dot} />
    <span className="b1">.</span>
    <div className={styles.dot} />
  </div>
);

export default TypingDots;
