import React, { useState } from "react";
import { AtfDesktop, AtfMobile } from "~components";
import { useInterval, useBreakpoints } from "~hooks";

const SLIDES = [
  {
    color: `var(--color-sick-lime)`,
    subheading: `Meetings with your boss.`,
    caption: `Makeup you can rely on. So you can face whatever life has to throw at you.`,
    img: `/images/atf/atf-image-1.png`,
    widgetType: `meeting`
  },
  {
    color: `var(--color-sunkiss)`,
    subheading: `Sunshine and first dates.`,
    caption: `Makeup you can rely on. So you can face whatever life has to throw at you.`,
    img: `/images/atf/atf-image-2.png`,
    widgetType: `weather`
  },
  {
    color: `var(--color-dont-make-me-blush)`,
    subheading: `Sunday Morning Coffee`,
    caption: `Makeup you can rely on. So you can face whatever life has to throw at you.`,
    img: `/images/atf/atf-image-3.png`,
    widgetType: `message`,
    widgetSender: `incoming`,
    widgetContent: `Get out of bed! We have brunch at 11. I have so much to tell you.`
  },
  {
    color: `var(--color-bright-pink)`,
    subheading: `Mercury In Retrograde.`,
    caption: `Makeup you can rely on. So you can face whatever life has to throw at you.`,
    img: `/images/atf/atf-image-4.png`,
    widgetType: `retrograde`
  },
  {
    color: `var(--color-darling-mauve)`,
    subheading: `Late Texts From Your Ex.`,
    caption: `Makeup you can rely on. So you can face whatever life has to throw at you.`,
    img: `/images/atf/atf-image-5.png`,
    widgetType: `message`,
    widgetSender: `incoming`,
    widgetContent: `Hey stranger, you up?`
  }
];

const LgfAnimatedHero = ({ id }) => {
  const bp = useBreakpoints().breakpoints;
  const isDesktop = bp.smallDesktop;

  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  useInterval(() => {
    const nextSlideIndex =
      activeSlideIndex === SLIDES.length - 1 ? 0 : activeSlideIndex + 1;
    setActiveSlideIndex(nextSlideIndex);
  }, 5000);

  return (
    <section sliceid={id}>
      {isDesktop ? (
        <AtfDesktop activeSlideIndex={activeSlideIndex} slides={SLIDES} />
      ) : (
        <AtfMobile activeSlideIndex={activeSlideIndex} slides={SLIDES} />
      )}
    </section>
  );
};

export default LgfAnimatedHero;
